import styled from "styled-components"

import { usePostReportFeedbackClicked } from "src/data/analytics/queries/ReportAnalyticsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { colors } from "src/ui/colors"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ReportsFeedbackBox() {
  const { t, langKeys } = useTranslate()
  const postReportFeedbackClicked = usePostReportFeedbackClicked()

  return (
    <MCard
      boxShadow={false}
      border={false}
      backgroundColor={colors.backgroundGrayV3}
      padding={spacing.L}
    >
      <Box>
        <div>
          <MText variant="heading3">{t(langKeys.reports_feedback_title)}</MText>
          <MText>{t(langKeys.reports_feedback_body)}</MText>
        </div>

        <MButtonLegacy
          variant="outlined"
          onClick={() => {
            postReportFeedbackClicked.mutate()
            window.open("https://forms.gle/yWUsuo1jPZqf8HjU9", "_blank")
          }}
        >
          {t(langKeys.feedback_action)}
        </MButtonLegacy>
      </Box>
    </MCard>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
  grid-template-columns: 1fr auto;
  align-items: center;
`
