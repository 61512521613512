import styled from "styled-components"

import { ImgCard } from "src/components/Reports/ImgCard"
import { breakpoint } from "src/constants/breakpoints"
import { usePostIncidentReportDownloadClicked } from "src/data/analytics/queries/ReportAnalyticsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import imgSofaSliver from "src/ui/images/photo-sofa-sliver.jpg"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseIncidentReportCard() {
  const { t, langKeys } = useTranslate()
  const postIncidentReportDownloadClicked =
    usePostIncidentReportDownloadClicked()

  function callTracking() {
    postIncidentReportDownloadClicked.mutate()
  }

  function handleCreateReport() {
    callTracking()
    window.open(FORM_HREF, "_blank", "noreferrer")
  }

  return (
    <ImgCard
      src={imgSofaSliver}
      alt="sofa"
      sources={[{ media: breakpoint.smallUp, srcSet: imgSofaSliver }]}
    >
      <MText variant="heading3">
        {t(langKeys.noise_incident_report_title)}
      </MText>
      <MText color="secondary">
        {t(langKeys.noise_incident_report_body)}{" "}
        <LearnMore beforeNav={callTracking} href={FORM_HREF} />
      </MText>

      <ButtonBox>
        <MButton size="medium" variant="secondary" onClick={handleCreateReport}>
          {t(langKeys.create_report)}
        </MButton>
      </ButtonBox>
    </ImgCard>
  )
}

const ButtonBox = styled.div`
  margin-top: ${spacing.XL};
`

const FORM_HREF = "https://forms.gle/k5wMDtRadz6sRhw7A"
