import {
  DateRangePicker,
  DateRangePickerProps,
  TDateRange,
} from "src/ui/DateRangePicker/DateRangePicker"

export type DateRangeDropdownProps = {
  dateRange: TDateRange
  setDateRange: (dateRange: TDateRange) => void
  dateRangePickerProps?: Partial<DateRangePickerProps>
}

export function DateRangeDropdown({
  dateRange,
  setDateRange,
  dateRangePickerProps,
}: DateRangeDropdownProps) {
  return (
    <DateRangePicker
      {...dateRangePickerProps}
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
      onDateRangeChange={({ startDate, endDate }: TDateRange) => {
        setDateRange({ startDate, endDate })
      }}
      dateRangeProps={{}}
      defaultPresetKey="MONTH"
      appearance="dropdown"
    ></DateRangePicker>
  )
}
